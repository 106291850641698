// src/pages/Signup.tsx
import React, { useState } from "react";
import { TextField, Button, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { signup } from "../services/authService";

const Signup: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSignup = async () => {
    try {
      await signup(email, password);
      // Redirect or display success message if needed
    } catch (error) {
      console.error("Signup error:", error);
    }
  };

  return (
    <Container>
      <Typography variant="h4">Signup</Typography>
      <TextField
        label="Email"
        fullWidth
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        label="Password"
        type="password"
        fullWidth
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button onClick={handleSignup} variant="contained">
        Signup
      </Button>
      <Typography>
        Already have an account? <Link to="/login">Log in here</Link>.
      </Typography>
    </Container>
  );
};

export default Signup;
