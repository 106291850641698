// src/services/authService.ts
import api from "../api/axiosConfig";

export const login = async (email: string, password: string) => {
  const response = await api.post("/auth/login", { email, password });
  localStorage.setItem("token", response.data.token);
  return response.data;
};

export const signup = async (email: string, password: string) => {
  const response = await api.post("/auth/signup", { email, password });
  localStorage.setItem("token", response.data.token);
  return response.data;
};
