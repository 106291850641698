import React from "react";
import { Button, Container, Typography } from "@mui/material";

const Landing: React.FC = () => {
  const handleGameNavigation = () => {
    window.location.href = "https://butterfly-world.abboxgames.com";
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h3" gutterBottom>
        Welcome to Butterfly World
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleGameNavigation}
      >
        Go to Butterfly World Game
      </Button>
    </Container>
  );
};

export default Landing;
